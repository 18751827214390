import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IRole extends IBaseEntity {
    id?: number;
    roleName?: string;
    authorityName?: string;
}

export class Role extends BaseEntity implements IRole {
    id?: number;
    roleName?: string;
    authorityName?: string;

    constructor(role?) {
        super();
        if (role) {
            this.id = role.id;
            this.roleName = role.roleName;
            this.authorityName = role.authorityName;
        }
    }

    clone(): Role {
        return new Role(this);
    }
}
