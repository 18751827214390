import {FuseNavigation} from '@fuse/types';

export const customerNavigation: FuseNavigation[] = [
    {
        id: 'menu',
        title: 'Menu',
        translate: 'NAV.MENU',
        type: 'collapsable',
        children: [
            {
                id: 'client-sale-orders',
                title: 'Sale Orders',
                translate: 'NAV.SALE_ORDER.TITLE',
                type: 'item',
                icon: 'file_copy',
                url: '/setup/client-sale-orders'
            },
            {
                id: 'site-visits',
                title: 'Site Visits',
                translate: 'NAV.SITE_VISITS.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/history/site-visits'
            },
            {
                id: 'invoice',
                title: 'Invoices',
                translate: 'NAV.INVOICE.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/invoices'
            },
            {
                id: 'credit-note',
                title: 'Credit Notes',
                translate: 'NAV.CREDIT_NOTE.TITLE',
                type: 'item',
                icon: 'description',
                url: '/setup/credit-notes'
            },
            {
                id: 'payments',
                title: 'Payments',
                translate: 'NAV.PAYMENTS.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/history/payments'
            },
            {
                id: 'customer',
                title: 'Customers',
                translate: 'NAV.CUSTOMER.TITLE',
                type: 'item',
                icon: 'supervisor_account',
                url: '/setup/customers'
            },
            {
                id: 'core-reports',
                title: 'Core Reports',
                translate: 'NAV.CORE_REPORTS.TITLE',
                type: 'item',
                icon: 'bar_chart',
                url: '/analytics/reports'
            },
            {
                id: 'customer-transactions',
                title: 'Customer Balance Ledger',
                translate: 'NAV.CUSTOMER_TRANSACTIONS.TITLE',
                type: 'item',
                icon: 'payments',
                url: '/history/customer-transactions'
            },
            {
                id: 'customer-aging',
                title: 'Customer Aging Report',
                translate: 'NAV.CUSTOMER_AGING_REPORTS.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/analytics/customer-aging-reports'
            },
            {
                id: 'bins',
                title: 'Bins',
                translate: 'NAV.BINS.TITLE',
                type: 'item',
                icon: 'location_city',
                url: '/setup/bins'
            }
        ]
    }
];
