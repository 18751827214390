import {FuseNavigation} from '@fuse/types';

export const adminNavigation: FuseNavigation[] = [
    {
        id: 'orders-management',
        title: 'Order Management',
        translate: 'NAV.ORDER_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'sale-order',
                title: 'Sale Orders',
                translate: 'NAV.SALE_ORDER.TITLE',
                type: 'item',
                icon: 'file_copy',
                url: '/setup/sale-orders'
            },
            {
                id: 'pick-order',
                title: 'Pick Orders',
                translate: 'NAV.PICK_ORDER.TITLE',
                type: 'item',
                icon: 'grading',
                url: '/setup/pick-orders'
            },
            {
                id: 'pack-order',
                title: 'Pack Orders',
                translate: 'NAV.PACK_ORDER.TITLE',
                type: 'item',
                icon: 'inventory-2',
                url: '/setup/pack-orders'
            },
            {
                id: 'load-order',
                title: 'Load Orders',
                translate: 'NAV.LOAD_ORDER.TITLE',
                type: 'item',
                icon: 'airport_shuttle',
                url: '/setup/load-orders'
            },
            {
                id: 'standing-order',
                title: 'Standing Order',
                translate: 'NAV.STANDING_ORDERS.TITLE',
                type: 'collapsable',
                icon: 'airport_shuttle',
                children: [
                    {
                        id: 'standing-sale-orders',
                        title: 'Standing Sale Orders',
                        translate: 'NAV.STANDING_SALE_ORDERS.TITLE',
                        type: 'item',
                        icon: 'airport_shuttle',
                        url: '/setup/standing-orders'
                    },
                    {
                        id: 'standing-load-orders',
                        title: 'Standing Load Orders',
                        translate: 'NAV.STANDING_LOAD_ORDERS.TITLE',
                        type: 'item',
                        icon: 'airport_shuttle',
                        url: '/setup/standing-load-orders'
                    }
                ]
            },

            {
                id: 'payments',
                title: 'Payments',
                translate: 'NAV.PAYMENTS.TITLE',
                type: 'item',
                icon: 'payments',
                url: '/history/payments'
            },
            {
                id: 'invoices',
                title: 'Invoices',
                translate: 'NAV.INVOICE.TITLE',
                type: 'collapsable',
                icon: 'request_quote',
                children: [
                    {
                        id: 'invoice',
                        title: 'Invoices',
                        translate: 'NAV.INVOICE.TITLE',
                        type: 'item',
                        icon: 'request_quote',
                        url: '/setup/invoices'
                    },
                    {
                        id: 'credit-note',
                        title: 'Credit Notes',
                        translate: 'NAV.CREDIT_NOTE.TITLE',
                        type: 'item',
                        icon: 'description',
                        url: '/setup/credit-notes'
                    }
                ]
            },
            {
                id: 'report',
                title: 'Reports',
                translate: 'NAV.REPORTS.TITLE',
                type: 'collapsable',
                icon: 'description',
                children: [
                    {
                        id: 'vehicle-inspections',
                        title: 'Vehicle Inspections',
                        translate: 'NAV.VEHICLE_INSPECTIONS.TITLE',
                        type: 'item',
                        icon: 'local_shipping',
                        url: '/history/vehicle-inspections'
                    },
                    {
                        id: 'site-visits',
                        title: 'Site Visits',
                        translate: 'NAV.SITE_VISITS.TITLE',
                        type: 'item',
                        icon: 'tour',
                        url: '/history/site-visits'
                    },
                    {
                        id: 'vehicle-unloads',
                        title: 'Vehicle Unloads',
                        translate: 'NAV.VEHICLE_UNLOADS.TITLE',
                        type: 'item',
                        icon: 'inventory-2',
                        url: '/history/vehicle-unloads'
                    },
                    {
                        id: 'daily-cash-reports',
                        title: 'Cash Ledger',
                        translate: 'NAV.CASH_DEPOSITS.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/history/driver-eod-reports'
                    },
                    // {
                    //     id: 'daily-deposits-reports',
                    //     title: 'Cash Deposits',
                    //     translate: 'NAV.DRIVER_DEPOSITS.TITLE',
                    //     type: 'item',
                    //     icon: 'credit_score',
                    //     url: '/history/driver-deposits-reports'
                    // },
                    {
                        id: 'stock-takes',
                        title: 'Stock Takes',
                        translate: 'NAV.STOCK_TAKES.TITLE',
                        type: 'item',
                        icon: 'inventory-2',
                        url: '/history/stock-takes'
                    },
                    {
                        id: 'core-reports',
                        title: 'Core Reports',
                        translate: 'NAV.CORE_REPORTS.TITLE',
                        type: 'item',
                        icon: 'bar_chart',
                        url: '/analytics/reports'
                    },
                    {
                        id: 'daily-activity-summaries',
                        title: 'Daily Activity Summaries',
                        translate: 'NAV.DAILY_ACTIVITY_SUMMARYS.TITLE',
                        type: 'item',
                        icon: 'summarize',
                        url: '/history/daily-activity-summary'
                    },
                    {
                        id: 'customer-transactions',
                        title: 'Customer Balance Ledger',
                        translate: 'NAV.CUSTOMER_TRANSACTIONS.TITLE',
                        type: 'item',
                        icon: 'payments',
                        url: '/history/customer-transactions'
                    },
                    {
                        id: 'customer-aging',
                        title: 'Customer Aging Report',
                        translate: 'NAV.CUSTOMER_AGING_REPORTS.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/analytics/customer-aging-reports'
                    },
                    {
                        id: 'cash-journal',
                        title: 'Cash Journal Report',
                        translate: 'NAV.CASH_JOURNAL_REPORTS.TITLE',
                        type: 'item',
                        icon: 'monetization_on',
                        url: '/analytics/cash-journal-reports'
                    },
                    {
                        id: 'shortfall-report',
                        title: 'Shortfall Report',
                        translate: 'NAV.SHORTFALL_REPORT.TITLE',
                        type: 'item',
                        icon: 'waterfall_chart',
                        url: '/analytics/shortfall-report'
                    },
                    {
                        id: 'employee-target',
                        title: 'Employee Target',
                        translate: 'NAV.EMPLOYEE_TARGET.TITLE',
                        type: 'item',
                        icon: 'ads_click',
                        url: '/history/employee-targets'
                    },
                    {
                        id: 'route-target',
                        title: 'Route Target',
                        translate: 'NAV.ROUTE_TARGET.TITLE',
                        type: 'item',
                        icon: 'ads_click',
                        url: '/history/route-targets'
                    },
                    {
                        id: 'product-cost-change',
                        title: 'Product Cost Change',
                        translate: 'NAV.PRODUCT_COST_CHANGE.TITLE',
                        type: 'item',
                        icon: 'price_change',
                        url: '/history/product-cost-change'
                    },
                    {
                        id: 'demand-report',
                        title: 'Demand Report',
                        translate: 'NAV.DEMAND_REPORT.TITLE',
                        type: 'item',
                        icon: 'flag',
                        url: '/analytics/demand-reports'
                    },
                    {
                        id: 'eod-survey',
                        title: 'EOD Survey',
                        translate: 'NAV.EOD_SURVEY.TITLE',
                        type: 'item',
                        icon: 'mood',
                        url: '/history/eod-surveys'
                    },
                    {
                        id: 'recipe-execution-report',
                        title: 'Recipe Execution Report',
                        translate: 'NAV.RECIPE_EXECUTION_REPORT.TITLE',
                        type: 'item',
                        icon: 'commit',
                        url: '/history/recipe-execution-report'
                    },
                    {
                        id: 'temperature-reports',
                        title: 'Temperature Report',
                        translate: 'NAV.TEMPERATURE_REPORTS.TITLE',
                        type: 'item',
                        icon: 'sunny',
                        url: '/history/temperature-reports'
                    },
                    {
                        id: 'stock-change-condition',
                        title: 'Stock Change Condition',
                        translate: 'NAV.STOCK_CHANGE_CONDITION.TITLE',
                        type: 'item',
                        icon: 'sunny',
                        url: '/history/stock-change-condition'
                    },
                    {
                        id: 'default-product-price-change',
                        title: 'Default Product Price',
                        translate: 'NAV.DEFAULT_PRODUCT_PRICE_CHANGE.TITLE',
                        type: 'item',
                        icon: 'sunny',
                        url: '/history/default-product-price-change'
                    }
                ]
            }
        ]
    },
    {
        id: 'audits',
        title: 'Audits',
        translate: 'NAV.AUDIT',
        type: 'collapsable',
        children: [
            {
                id: 'mail-audits',
                title: 'Mail Audit',
                translate: 'NAV.MAIL_AUDIT.TITLE',
                type: 'item',
                url: '/history/mail-audits',
                icon: 'receipt'
            },
            {
                id: 'auditLog',
                title: 'Audit Log',
                translate: 'NAV.AUDIT_LOG.TITLE',
                type: 'item',
                url: '/history/audit-log',
                icon: 'bar_chart'
            },
            {
                id: 'app-request',
                title: 'App Request',
                translate: 'NAV.APP_REQUEST.TITLE',
                type: 'item',
                icon: 'bar_chart',
                url: '/history/app-requests'
            },
            {
                id: 'async-requests',
                title: 'Async Requests',
                translate: 'NAV.ASYNC_REQUESTS.TITLE',
                type: 'item',
                url: '/setup/async-requests',
                icon: 'bar_chart'
            }
        ]
    },
    {
        id: 'route-management',
        title: 'Route Management',
        translate: 'NAV.ROUTE_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'route-planner',
                title: 'Route Planner',
                translate: 'NAV.ROUTE_PLANNER.TITLE',
                type: 'item',
                icon: 'directions',
                url: '/scheduling/route-planner'
            },
            {
                id: 'route-command',
                title: 'Route Monitor',
                translate: 'NAV.ROUTE_COMMAND.TITLE',
                type: 'item',
                icon: 'monitor_heart',
                url: '/scheduling/route-command'
            },
            {
                id: 'route-schedules',
                title: 'Route Schedules',
                translate: 'NAV.ROUTE_SCHEDULES.TITLE',
                type: 'item',
                icon: 'schedule',
                url: '/scheduling/route-schedules'
            },
            {
                id: 'route-history',
                title: 'Route History',
                translate: 'NAV.ROUTE_HISTORY.TITLE',
                type: 'item',
                icon: 'history',
                url: '/scheduling/route-history'
            },
            {
                id: 'vehicle',
                title: 'Vehicles',
                translate: 'NAV.VEHICLE.TITLE',
                type: 'collapsable',
                icon: 'local_shipping',
                children: [
                    {
                        id: 'vehicles',
                        title: 'Vehicles',
                        translate: 'NAV.VEHICLE.TITLE',
                        type: 'item',
                        icon: 'local_shipping',
                        url: '/setup/vehicles'
                    },
                    {
                        id: 'vehicle-type',
                        title: 'Vehicle Types',
                        translate: 'NAV.VEHICLE_TYPE.TITLE',
                        type: 'item',
                        icon: 'style',
                        url: '/setup/vehicle-types'
                    },
                    {
                        id: 'vehicle-check-list',
                        title: 'Safety Profiles',
                        translate: 'NAV.VEHICLE_CHECK_LIST.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/setup/vehicle-check-lists'
                    },
                    {
                        id: 'vehicle-skills',
                        title: 'Vehicle Skills',
                        translate: 'NAV.VEHICLE_SKILL.TITLE',
                        type: 'item',
                        icon: 'trip_origin',
                        url: '/setup/vehicle-skills'
                    }
                ]
            },
            {
                id: 'route',
                title: 'Routes',
                translate: 'NAV.ROUTE.TITLE',
                type: 'collapsable',
                icon: 'directions',
                children: [
                    {
                        id: 'routes',
                        title: 'Routes',
                        translate: 'NAV.ROUTE.TITLE',
                        type: 'item',
                        icon: 'directions',
                        url: '/setup/routes'
                    },
                    {
                        id: 'route-type',
                        title: 'Route Types',
                        translate: 'NAV.ROUTE_TYPE.TITLE',
                        type: 'item',
                        icon: 'style',
                        url: '/setup/route-types'
                    },
                    {
                        id: 'stop-type',
                        title: 'Stop Types',
                        translate: 'NAV.STOP_TYPE.TITLE',
                        type: 'item',
                        icon: 'group_work',
                        url: '/setup/stop-types'
                    }
                    // ,
                    // {
                    //     id: 'route-map-symbol',
                    //     title: 'Route Map Symbols',
                    //     translate: 'NAV.ROUTE_MAP_SYMBOL.TITLE',
                    //     type: 'item',
                    //     icon: 'trip_origin',
                    //     url: '/setup/route-map-symbols'
                    // }
                ]
            }
        ]
    },
    {
        id: 'inventory-management',
        title: 'Inventory Management',
        translate: 'NAV.INVENTORY_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'incoming-inventory',
                title: 'Receive/Adjust Inventory',
                translate: 'NAV.INCOMING_INVENTORY.TITLE',
                type: 'item',
                icon: 'store',
                url: '/setup/incoming-inventories'
            },
            {
                id: 'warehouse-transfer',
                title: 'Warehouse Transfer',
                translate: 'NAV.WAREHOUSE_TRANSFER.TITLE',
                type: 'item',
                icon: 'store',
                url: '/setup/warehouse-transfers'
            },
            {
                id: 'inventory-stock',
                title: 'Inventory Stock',
                translate: 'NAV.INVENTORY_STOCK.TITLE',
                type: 'item',
                icon: 'inventory',
                url: '/setup/inventory-stock'
            },
            {
                id: 'purchase-order',
                title: 'Purchase Orders',
                translate: 'NAV.PURCHASE_ORDERS.TITLE',
                type: 'item',
                icon: 'shop_two',
                url: '/setup/purchase-orders'
            },
            {
                id: 'purchase-payments',
                title: 'Purchase Payments',
                translate: 'NAV.PURCHASE_PAYMENTS.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/purchase-payments'
            },
            {
                id: 'purchase-invoice',
                title: 'Purchase Invoice',
                translate: 'NAV.PURCHASE_INVOICE.TITLE',
                type: 'item',
                icon: 'request_quote',
                url: '/setup/purchase-invoice'
            },
            {
                id: 'purchase-credit-note',
                title: 'Purchase Debit Note',
                translate: 'NAV.PURCHASE_CREDIT_NOTE.TITLE',
                type: 'item',
                icon: 'description',
                url: '/setup/purchase-credit-notes'
            },
            {
                id: 'supplier-payment-term',
                title: 'Supplier Payment Terms',
                translate: 'NAV.SUPPLIER_PAYMENT_TERM.TITLE',
                type: 'item',
                icon: 'money',
                url: '/setup/supplier-payment-terms'
            },
            {
                id: 'inventory-transaction',
                title: 'Inventory Ledger',
                translate: 'NAV.INVENTORY_TRANSACTIONS.TITLE',
                type: 'item',
                icon: 'file_copy',
                url: '/setup/inventory-transactions'
            },
            {
                id: 'recipes',
                title: 'Recipes',
                translate: 'NAV.RECIPES.TITLE',
                type: 'item',
                icon: 'local_grocery_store',
                url: '/setup/recipes'
            },
            {
                id: 'productionVouchers',
                title: 'Production Vouchers',
                translate: 'NAV.PRODUCTION_VOUCHERS.TITLE',
                type: 'item',
                icon: 'local_grocery_store',
                url: '/setup/production-vouchers'
            },
            {
                id: 'eodInventoryReport',
                title: 'EOD Inventory Report',
                translate: 'NAV.EOD_INVENTORY_REPORT.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/eod-inventory-report'
            },
            {
                id: 'supplier-transactions',
                title: 'Supplier Ledger',
                translate: 'NAV.SUPPLIER_TRANSACTIONS.TITLE',
                type: 'item',
                icon: 'workspaces',
                url: '/setup/supplier-transactions'
            }
        ]
    },
    {
        id: 'survey-management',
        title: 'Survey Management',
        translate: 'NAV.SURVEY_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'survey',
                title: 'Surveys',
                translate: 'NAV.SURVEY.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/surveys'
            },
            {
                id: 'surveyReport',
                title: 'Survey Report',
                translate: 'NAV.SURVEY_REPORT.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/survey-report'
            }
        ]
    },
    {
        id: 'catalogue-management',
        title: 'Catalogue Management',
        translate: 'NAV.CATALOGUE_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'catalogue',
                title: 'Catalogues',
                translate: 'NAV.CATALOGUE.TITLE',
                type: 'item',
                icon: 'description',
                url: '/setup/catalogues'
            },
            {
                id: 'presentation',
                title: 'Presentations',
                translate: 'NAV.PRESENTATION.TITLE',
                type: 'item',
                icon: 'description',
                url: '/setup/presentations'
            },
            {
                id: 'resource-items',
                title: 'Resource Items',
                translate: 'NAV.RESOURCE_ITEM.TITLE',
                type: 'item',
                icon: 'description',
                url: '/setup/resource-items'
            },
            {
                id: 'resource-groups',
                title: 'Resource Groups',
                translate: 'NAV.RESOURCE_GROUPS.TITLE',
                type: 'item',
                icon: 'description',
                url: '/setup/resource-groups'
            }
        ]
    },
    {
        id: 'company-management',
        title: 'Company Management',
        translate: 'NAV.COMPANY.TITLE',
        type: 'collapsable',
        children: [
            {
                id: 'company',
                title: 'Company',
                translate: 'NAV.COMPANY.TITLE',
                type: 'item',
                icon: 'home',
                url: '/setup/company'
            },
            {
                id: 'users',
                title: 'Users',
                translate: 'NAV.USERS.TITLE',
                type: 'item',
                url: '/setup/users',
                icon: 'person'
            },
            {
                id: 'roles',
                title: 'Roles',
                translate: 'NAV.ROLES.TITLE',
                type: 'item',
                url: '/setup/roles',
                icon: 'person'
            },
            {
                id: 'branch',
                title: 'Branches',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'domain',
                url: '/setup/branches'
                // Don't remove this commented out code
                // badge    : {
                //     title    : '25',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : 'var(--light-red)',
                //     fg       : 'var(--white)'
                // }
            },
            {
                id: 'warehouse',
                title: 'Warehouses',
                translate: 'NAV.WAREHOUSE.TITLE',
                type: 'item',
                icon: 'location_city',
                url: '/setup/warehouses'
            },
            {
                id: 'workshop',
                title: 'Workshop',
                translate: 'NAV.WORKSHOP.TITLE',
                type: 'item',
                icon: 'location_city',
                url: '/setup/workshops'
            },
            {
                id: 'siteVisitSkipReasons',
                title: 'Site Visit Skip Reasons',
                translate: 'NAV.SITE_VISIT_SKIP_REASONS.TITLE',
                type: 'item',
                icon: 'location_city',
                url: '/setup/site-visit-skip-reasons'
            },
            {
                id: 'customFieldGroup',
                title: 'Custom Field Group',
                translate: 'NAV.CUSTOM_FIELD_GROUPS.TITLE',
                type: 'item',
                icon: 'workspaces',
                url: '/setup/custom-field-groups'
            },
            {
                id: 'customField',
                title: 'Custom Field',
                translate: 'NAV.CUSTOM_FIELDS.TITLE',
                type: 'item',
                icon: 'location_city',
                url: '/setup/custom-fields'
            },
            {
                id: 'customPdfTemplate',
                title: 'Custom PDF Template',
                translate: 'NAV.CUSTOM_PDF_TEMPLATES.TITLE',
                type: 'item',
                icon: 'location_city',
                url: '/setup/custom-pdf-templates'
            },
            {
                id: 'accounts-package-setup',
                title: 'Accounts Package Setup',
                translate: 'NAV.ACCOUNTS_PACKAGE_SETUP.TITLE',
                type: 'item',
                icon: 'description',
                url: '/setup/accounts-package'
            },
            {
                id: 'integration-mapping',
                title: 'Integration Mapping',
                translate: 'NAV.INTEGRATION_MAPPING.TITLE',
                type: 'item',
                icon: 'description',
                url: '/setup/integration-mapping'
            },
            {
                id: 'branch-payments',
                title: 'Branch Deposits',
                translate: 'NAV.BRANCH_PAYMENTS.TITLE',
                type: 'item',
                icon: 'location_city',
                url: '/setup/branch-payments'
            },
            {
                id: 'branch-transactions',
                title: 'Branch Ledger',
                translate: 'NAV.BRANCH_TRANSACTIONS.TITLE',
                type: 'item',
                icon: 'workspaces',
                url: '/setup/branch-transactions'
            },
            {
                id: 'bin-types',
                title: 'Bin types',
                translate: 'NAV.BIN_TYPES.TITLE',
                type: 'item',
                icon: 'workspaces',
                url: '/setup/bin-types'
            },
            {
                id: 'bins',
                title: 'Bins',
                translate: 'NAV.BINS.TITLE',
                type: 'item',
                icon: 'location_city',
                url: '/setup/bins'
            },
            {
                id: 'budgets',
                title: 'Budgets',
                translate: 'NAV.BUDGETS.TITLE',
                type: 'item',
                icon: 'location_city',
                url: '/setup/budgets'
            }
        ]
    },
    {
        id: 'employee-management',
        title: 'Employee Management',
        translate: 'NAV.EMPLOYEE_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'employee',
                title: 'Employees',
                translate: 'NAV.EMPLOYEE.TITLE',
                type: 'item',
                icon: 'person',
                url: '/setup/employees'
            },
            {
                id: 'employee-group',
                title: 'Employee Groups',
                translate: 'NAV.EMPLOYEE_GROUP.TITLE',
                type: 'item',
                icon: 'group',
                url: '/setup/employee-groups'
            },
            {
                id: 'handheld-profile',
                title: 'Handheld Profiles',
                translate: 'NAV.HANDHELD_PROFILE.TITLE',
                type: 'item',
                icon: 'mobile_friendly',
                url: '/setup/handheld-profiles'
            },
            {
                id: 'employee-expenses',
                title: 'Employee Expenses',
                translate: 'NAV.EMPLOYEE_EXPENSES.TITLE',
                type: 'item',
                icon: 'attach_money',
                url: '/setup/employee-expenses'
            },
            {
                id: 'expense-types',
                title: 'Expense Types',
                translate: 'NAV.EXPENSE_TYPES.TITLE',
                type: 'item',
                icon: 'attach_money',
                url: '/setup/expense-types'
            },
            {
                id: 'expense-payment-methods',
                title: 'Expense Payment Methods',
                translate: 'NAV.EXPENSE_PAYMENT_METHODS.TITLE',
                type: 'item',
                icon: 'attach_money',
                url: '/setup/expense-payment-methods'
            }
        ]
    },
    {
        id: 'customer-management',
        title: 'Customer Management',
        translate: 'NAV.CUSTOMER_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'customer',
                title: 'Customers',
                translate: 'NAV.CUSTOMER.TITLE',
                type: 'item',
                icon: 'supervisor_account',
                url: '/setup/customers'
            },
            {
                id: 'customer-group',
                title: 'Customer Groups',
                translate: 'NAV.CUSTOMER_GROUP.TITLE',
                type: 'item',
                icon: 'group_work',
                url: '/setup/customer-groups'
            },
            {
                id: 'access-codes',
                title: 'Access Codes',
                translate: 'NAV.DRIVER_ACCESS_CODES.TITLE',
                type: 'item',
                icon: 'library_books',
                url: '/setup/driver-access-codes'
            },
            {
                id: 'payment-type',
                title: 'Payment Types',
                translate: 'NAV.PAYMENT_TYPE.TITLE',
                type: 'item',
                icon: 'style',
                url: '/setup/payment-types'
            },
            {
                id: 'credit-term',
                title: 'Credit Terms',
                translate: 'NAV.CREDIT_TERM.TITLE',
                type: 'item',
                icon: 'money',
                url: '/setup/credit-terms'
            },
            {
                id: 'credit-class',
                title: 'Credit Class',
                translate: 'NAV.CREDIT_CLASS.TITLE',
                type: 'item',
                icon: 'credit_card',
                url: '/setup/credit-classes'
            },
            {
                id: 'statement-code',
                title: 'Statement Codes',
                translate: 'NAV.STATEMENT_CODE.TITLE',
                type: 'item',
                icon: 'library_books',
                url: '/setup/statement-codes'
            },
            {
                id: 'major-account-code',
                title: 'Major Account Codes',
                translate: 'NAV.MAJOR_ACCOUNT_CODE.TITLE',
                type: 'item',
                icon: 'account_balance_wallet',
                url: '/setup/major-account-codes'
            },
            {
                id: 'service-priority-code',
                title: 'Service Priority Codes',
                translate: 'NAV.SERVICE_PRIORITY_CODE.TITLE',
                type: 'item',
                icon: 'priority_high',
                url: '/setup/service-priority-codes'
            },
            // {
            //     id: 'dunning-message-code',
            //     title: 'Dunning Message Codes',
            //     translate: 'NAV.DUNNING_MESSAGE_CODE.TITLE',
            //     type: 'item',
            //     icon: 'message',
            //     url: '/setup/dunning-message-codes'
            // },
            {
                id: 'customer-sms-text',
                title: 'Customer SMS Texts',
                translate: 'NAV.CUSTOMER_SMS_TEXT.TITLE',
                type: 'item',
                icon: 'sms',
                url: '/setup/customer-sms-texts'
            },
            {
                id: 'territories',
                title: 'Territories',
                translate: 'NAV.TERRITORIES.TITLE',
                type: 'item',
                icon: 'south_america',
                url: '/setup/territories'
            }
        ]
    },
    {
        id: 'product-management',
        title: 'Product Management',
        translate: 'NAV.PRODUCT_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'product',
                title: 'Products',
                translate: 'NAV.PRODUCT.TITLE',
                type: 'item',
                icon: 'local_grocery_store',
                url: '/setup/products'
            },
            {
                id: 'supplier',
                title: 'Suppliers',
                translate: 'NAV.SUPPLIERS.TITLE',
                type: 'item',
                icon: 'supervisor_account',
                url: '/setup/suppliers'
            },
            {
                id: 'supplier-group',
                title: 'Supplier Groups',
                translate: 'NAV.SUPPLIER_GROUPS.TITLE',
                type: 'item',
                icon: 'group_work',
                url: '/setup/supplier-groups'
            },
            {
                id: 'product-attribute-types',
                title: 'Product Attribute Types',
                translate: 'NAV.PRODUCT_ATTRIBUTE_TYPE.TITLE',
                type: 'item',
                icon: 'group_work',
                url: '/setup/product-attribute-types'
            },
            {
                id: 'product-class',
                title: 'Product Class',
                translate: 'NAV.PRODUCT_CLASS.TITLE',
                type: 'item',
                icon: 'group_work',
                url: '/setup/product-class'
            },
            {
                id: 'product-group',
                title: 'Product Group',
                translate: 'NAV.PRODUCT_GROUP.TITLE',
                type: 'item',
                icon: 'category',
                url: '/setup/product-groups'
            },
            {
                id: 'deposit-item',
                title: 'Deposit Items',
                translate: 'NAV.DEPOSIT_ITEM.TITLE',
                type: 'item',
                icon: 'add_shopping_cart',
                url: '/setup/deposit-items'
            },
            // {
            //     id: 'product-list',
            //     title: 'Product Lists',
            //     translate: 'NAV.PRODUCT_LIST.TITLE',
            //     type: 'item',
            //     icon: 'list_alt',
            //     url: '/setup/product-lists'
            // },
            {
                id: 'packaging',
                title: 'Packaging',
                translate: 'NAV.PACKAGING.TITLE',
                type: 'item',
                icon: 'redeem',
                url: '/setup/packagings'
            },
            {
                id: 'delivery-package-type',
                title: 'Delivery Package Types',
                translate: 'NAV.DELIVERY_PACKAGE_TYPE.TITLE',
                type: 'item',
                icon: 'style',
                url: '/setup/delivery-package-types'
            },
            {
                id: 'promotions',
                title: 'Promotions',
                translate: 'NAV.PROMOTIONS.TITLE',
                type: 'item',
                icon: 'money',
                url: '/setup/promotions'
            },
            {
                id: 'price-list',
                title: 'Price Lists',
                translate: 'NAV.PRICE_LIST.TITLE',
                type: 'item',
                icon: 'money',
                url: '/setup/price-lists'
            },
            {
                id: 'product-list',
                title: 'Product Lists',
                translate: 'NAV.PRODUCT_LIST.TITLE',
                type: 'item',
                icon: 'money',
                url: '/setup/product-lists'
            },
            // {
            //     id: 'order-rejection-code',
            //     title: 'Order Rejection Codes',
            //     translate: 'NAV.ORDER_REJECTION_CODE.TITLE',
            //     type: 'item',
            //     icon: 'not_interested',
            //     url: '/setup/order-rejection-codes'
            // },
            // {
            //     id: 'product-tax-category',
            //     title: 'Product Tax Categories',
            //     translate: 'NAV.PRODUCT_TAX_CATEGORY.TITLE',
            //     type: 'item',
            //     icon: 'attach_money',
            //     url: '/setup/product-tax-categories'
            // },
            {
                id: 'unit-of-measure',
                title: 'Unit of Measure',
                translate: 'NAV.UNIT_OF_MEASURE.TITLE',
                type: 'item',
                icon: 'looks_one',
                url: '/setup/unit-of-measures'
            },
            {
                id: 'reason-code',
                title: 'Reason Codes',
                translate: 'NAV.REASON_CODE.TITLE',
                type: 'item',
                icon: 'library_books',
                url: '/setup/reason-codes'
            },
            {
                id: 'product-imei-track',
                title: 'IMEI Tracking',
                translate: 'NAV.PRODUCT_IMEI_TRACK.TITLE',
                type: 'item',
                icon: 'library_books',
                url: '/setup/product-imei-tracks'
            },
            {
                id: 'brand',
                title: 'Brand',
                translate: 'NAV.BRAND.TITLE',
                type: 'item',
                icon: 'category',
                url: '/setup/brands'
            },
            {
                id: 'equipments',
                title: 'Equipments',
                translate: 'NAV.EQUIPMENTS.TITLE',
                type: 'item',
                icon: 'construction',
                url: '/setup/equipments'
            },
            {
                id: 'product-resequence',
                title: 'Product Re-Sequence',
                translate: 'NAV.PRODUCT_RE_SEQUENCE.TITLE',
                type: 'item',
                icon: 'sort',
                url: '/setup/product-resequence'
            }
        ]
    },
    // {
    //     id: 'task-management',
    //     title: 'Task Management',
    //     translate: 'NAV.TASK_MANAGEMENT',
    //     type: 'item',
    //     icon: 'mobile_friendly',
    //     url: '/setup/task-management'
    // },
    // {
    //     id: 'file-management',
    //     title: 'File Management',
    //     translate: 'NAV.FILE_MANAGEMENT',
    //     type: 'item',
    //     icon: 'file_copy',
    //     url: '/setup/file-management'
    // },
    // {
    //     id: 'alerting',
    //     title: 'Alerting',
    //     translate: 'NAV.ALERTING',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'alert',
    //             title: 'Alert',
    //             translate: 'NAV.ALERT.TITLE',
    //             type: 'item',
    //             icon: 'library_books',
    //             url: '/setup/alerts'
    //         }
    //     ]
    // },
    {
        id: 'import',
        title: 'IMPORT / EXPORT',
        translate: 'NAV.IMPEX',
        type: 'collapsable',
        children: [
            {
                id: 'import-data',
                title: 'Import Data',
                translate: 'NAV.IMPORT_DATA.TITLE',
                type: 'item',
                icon: 'open_in_browser',
                url: '/setup/import-data'
            }
            // // Removed re-index from menu https://app.clickup.com/t/85ztfhda0
            // {
            //     id: 'reindex',
            //     title: 'Admin Reindex',
            //     translate: 'NAV.ADMIN_REINDEX.TITLE',
            //     type: 'item',
            //     url: '/setup/reindex',
            //     icon: 'open_in_browser'
            // }
        ]
    },
    {
        id: 'workflows',
        title: 'Workflows',
        translate: 'NAV.WORKFLOW.TITLE',
        type: 'collapsable',
        children: [
            {
                id: 'email-workflows',
                title: 'Email Workflows',
                translate: 'NAV.EMAIL_WORKFLOW.TITLE',
                type: 'item',
                icon: 'mail',
                url: '/setup/email-workflows'
            },
            {
                id: 'delete-workflows',
                title: 'Delete Workflows',
                translate: 'NAV.DELETE_WORKFLOW.TITLE',
                type: 'item',
                icon: 'delete',
                url: '/setup/delete-workflows'
            },
            {
                id: 'update-workflows',
                title: 'Update Workflows',
                translate: 'NAV.UPDATE_WORKFLOW.TITLE',
                type: 'item',
                icon: 'edit',
                url: '/setup/update-workflows'
            }
        ]
    },
    {
        id: 'service-orders-management',
        title: 'Service Orders',
        translate: 'NAV.SERVICE_ORDERS',
        type: 'collapsable',
        children: [
            {
                id: 'serviceItems',
                title: 'Service Items',
                translate: 'NAV.SERVICE_ITEMS.TITLE',
                type: 'item',
                icon: 'support_agent',
                url: '/service-orders/service-items'
            },
            {
                id: 'serviceOrders',
                title: 'Service Orders',
                translate: 'NAV.SERVICE_ORDERS',
                type: 'item',
                icon: 'room_service',
                url: '/service-orders/service-orders'
            }
        ]
    },
    {
        id: 'data-analytics',
        title: 'Analytics',
        translate: 'NAV.DATA_ANALYTICS',
        type: 'item',
        icon: 'file_copy',
        url: '/setup/data-analytics'
    }
];
