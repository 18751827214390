import {format} from 'date-fns';

export class DiTime {
    hour: number;
    min: number;

    constructor(timeObj?: {hour: number; min: number}) {
        this.hour = timeObj.hour;
        this.min = timeObj.min;
    }

    static newInstance(obj: any): DiTime {
        let instance = null;
        if (obj) {
            if (typeof obj === 'string') {
                const timeString = obj.split(':');
                instance = new DiTime({
                    hour: +timeString[0],
                    min: +timeString[1]
                });
            } else if (obj instanceof Date) {
                instance = new DiTime({
                    hour: obj.getHours(),
                    min: obj.getMinutes()
                });
            } else if (obj instanceof DiTime) {
                instance = obj;
            }
        }
        return instance;
    }

    static currentTime(): DiTime {
        const now = new Date();
        return new DiTime({hour: now.getHours(), min: now.getMinutes()});
    }

    toString(): string {
        return `${this.padLeftZero(this.hour, 2)}:${this.padLeftZero(this.min, 2)}`;
    }

    toJSON(): string {
        return this.toString();
    }

    compare(bTime: DiTime): number {
        if (bTime) {
            if (this.hour === bTime.hour) {
                return this.min - bTime.min;
            }
            return this.hour - bTime.hour;
        }
        return -1;
    }

    asDate(): Date {
        const timeAsDate = new Date();
        timeAsDate.setHours(this.hour, this.min);
        return timeAsDate;
    }

    private padLeftZero(numberToPad: number, noOfDigits: number): string {
        const str = '' + numberToPad;
        return ('0'.repeat(noOfDigits) + str).substring(str.length);
    }
}
