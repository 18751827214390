export const environment = {
    production: process.env.NODE_ENV === 'production',
    hmr: false,
    serverApiUrl: 'https://staging-api.routemagic.co.uk/',
    env: 'demo',
    heremapsCredentials: {
        apiKey: '9T-n-T9TIx5AnpOJYZA4dcsNtxMqMd73xtD1jlLL6wo'
    },
    googleMaps: {
        apiKey: 'AIzaSyCBvI2pbMwTbXTZ2p0vVco3e0ok7fNQkeU'
    },
    graphhopper: {
        apiKey: '963a3f57-7782-4be3-9f2b-10a4df31ed02'
    },
    logUrl: 'https://lwk2e2mbqwaefp46ubrywqsxx40iombm.lambda-url.eu-west-1.on.aws/',
    newrelic: {
        init: {distributed_tracing: {enabled: true}, privacy: {cookies_enabled: true}},
        loader_config: {
            accountID: '3458937',
            trustKey: '3458937',
            agentID: '538507176',
            licenseKey: 'NRJS-5eb9afcf1921f2210b6',
            applicationID: '518127276'
        },
        info: {
            beacon: 'bam.eu01.nr-data.net',
            errorBeacon: 'bam.eu01.nr-data.net',
            licenseKey: 'NRJS-5eb9afcf1921f2210b6',
            applicationID: '518127276',
            sa: 1
        }
    },
    stockAnalyticsLink:
        'https://lookerstudio.google.com/embed/reporting/c71ba490-d31e-490a-b56e-0edb55950c2c/page/FWmtD?params=%7B"df41":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580tenant"%7D',
    //make sure when you add a new api key, you add it to the csvBoxKeys object accross env.ts, env.prod.ts, env.staging.ts and ensure that it is whitelisted on the CSVbox admin panel
    csvBoxKeys: {
        saleOrder: {
            csvBoxLicenceKey: '8AE1RRgPOftbLxFTajk9byMVPOVmh6',
            csvBoxPackageImportLicenceKey: 'pNIyTxQg3QC7GR1ArVmQv3aK92MgZp'
        },
        payment: {
            csvBoxLicenceKey: 'ObkOr0qsHTIVXNvUEVWIA7IZCJ5ijX'
        },
        routePlanner: {
            csvBoxLicenceKey: 'AvHIxs8WIMvd5sYA10p1CkQXJ5w3vk'
        },
        routeSchedule: {
            csvBoxLicenceKey: 'o6NTWa1bZmvS9bTdNeGZk5ONndprco'
        },
        serviceOrder: {
            csvBoxLicenceKey: '17WeVPZbAAzCjzG33Bebpp6TJHgw3f'
        },
        branch: {
            csvBoxLicenceKey: 'cz1DZ2n6B8IfJOq6YVojPJHBABKvps'
        },
        company: {
            csvBoxLicenceKey: 'hvNItWotdXExBPzmA6hx5aiPRP06a5'
        },
        customer: {
            csvBoxLicenceKey: '1f0GIBCmGrQaoZ71ZuUbsXQdvSpBxK',
            csvBoxDeliveryPointImportLicenceKey: 'j3hYqvGmpUqgo1DNjPQe3yBNCeVDQ5',
            csvBoxCustomFieldValuesImportLicenceKey: 'Ncg4L9FWDV90qtyv7m7ZRYpVVEK3Ux'
        },
        employee: {
            csvBoxLicenceKey: 'Udl47M4aPhrCnul5dhdVRVfIxz1xpQ'
        },
        incomingInventory: {
            csvBoxLicenceKey: 'cXfXnfS5h62tkSQISOLYzlZv2gkMEu'
        },
        loadOrder: {
            csvBoxLicenceKey: '6MNPXGdBrbNcJxKWqqgLpDlwCZDUXI'
        },
        priceList: {
            csvBoxLicenceKey: 'lbjqJhxTzJwtTXUpy7S94PmUVRrv22'
        },
        product: {
            csvBoxLicenceKey: 'ixI2VuLygabefb7waCGZnnsSSeSPiO',
            csvBoxCustomFieldValuesLicenceyKey: '4Wmii9gGNNXJzyFyWhmD1WjlvtEnwe'
        },
        productClass: {
            csvBoxLicenceKey: 'R2L7k0gxlka4nkRjXHgIzfiAcp5toS'
        },
        productGroup: {
            csvBoxLicenceKey: '94qTF7e0tlqOQjwcHsl2Ys57fMoBGC'
        },
        productImeiTrack: {
            csvBoxLicenceKey: '4BA05JMjh6cb2Rll782nsLwGdKPDzy'
        },
        purchaseOrder: {
            csvBoxLicenceKey: 'C8tADzvq0PSTzk036TUbmu4UxrRVRM'
        },
        route: {
            csvBoxLicenceKey: 'udQhd1km8GSxQxuszkpty4uoZxgSOY'
        },
        standingLoadOrder: {
            csvBoxLicenceKey: 'xHSaP4yTeQRr8ltuhs4EYCKe4RbPIp'
        },
        supplier: {
            csvBoxLicenceKey: 'Y67XrXU3FhCYSQDsKx8QoCBoycTDoR'
        },
        vehicle: {
            csvBoxLicenceKey: 'SxJw7SvM286w72XucLLl3LltqgAKl3'
        },
        warehouse: {
            csvBoxLicenceKey: 'l80poao3aUaYBSGCWxlRRAT37qFD7k'
        },
        warehouseTransfer: {
            csvBoxLicenceKey: 'i5f89t3hB2QB175L6jS6bztxmHI4dx'
        },
        workshop: {
            csvBoxLicenceKey: 'WEvgwgLlz9Ac09jSDmwT9fjXZPjrAb'
        }
    },
    routemagicAssistant: {
        enabled: false
    }
};
