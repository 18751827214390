import {FuseNavigation} from '@fuse/types';

export const userFeature: FuseNavigation[] = [
    {
        id: 'features',
        title: 'Features',
        type: 'group',
        children: [
            {
                id: 'driver-app-access',
                title: 'Driver App Access',
                type: 'group',
                children: []
            }
        ]
    }
];
