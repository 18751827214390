import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';
import {createRequestOption} from 'app/blocks/util/request-util';
import {Resource} from 'app/constants/resource';
import {ImportResult} from 'app/main/private/setup/import-data/model/import-result.model';

export default class EntityCrudService<T> {
    protected _resourceUrl: string | null;
    protected _customerResourceUrl: string | null;
    protected _resourceSearchUrl: string | null;
    protected _customerResourceSearchUrl: string | null;

    public readonly resourceName = this._resourceName;
    isSuperUser = false;
    lastSearchedTerm = '';

    constructor(
        protected http: HttpClient,
        protected _resourceName: Resource,
        protected _entityCreator: (obj?) => T
    ) {
        const dashedResourceName = this.camelCaseToDashed(this._resourceName.toString());
        this.isSuperUser = window.localStorage.getItem('isSuperUser') === 'true';
        this._resourceUrl = SERVER_API_URL + 'api/' + (this.isSuperUser ? 'super-admin/' : '') + dashedResourceName;
        this._resourceSearchUrl = SERVER_API_URL + 'api/' + (this.isSuperUser ? 'super-admin/' : '') + '_search/' + dashedResourceName;
        this._customerResourceUrl = SERVER_API_URL + 'api/cp/' + dashedResourceName;
        this._customerResourceSearchUrl = SERVER_API_URL + 'api/cp/_search/' + dashedResourceName;
    }

    export = (ids: Set<number>, params?: any): Promise<HttpResponse<ArrayBuffer>> => {
        const requestParams = {ids: Array.from(ids).toString(), ...params};
        const options = createRequestOption(requestParams);
        return this.http
            .get(this._resourceUrl + '/export', {
                params: options,
                responseType: 'arraybuffer',
                observe: 'response'
            })
            .toPromise();
    };

    exportToAccountingPackage = (ids: any): Promise<ImportResult> => {
        const requestParams = new HttpParams().set('ids', ids);
        const REQUEST_URI = this._resourceUrl + '/export-to-accounting-package';
        return this.http.post<ImportResult>(REQUEST_URI, requestParams).toPromise();
    };

    importFromAccountingPackage = (): Promise<ImportResult> => {
        const REQUEST_URI = this._resourceUrl + '/import-from-accounting-package';
        return this.http.post<ImportResult>(REQUEST_URI, {}).toPromise();
    };

    createHttp = (entity: T): Promise<HttpResponse<T>> => {
        return this.http.post<T>(this._resourceUrl, entity, {observe: 'response'}).toPromise();
    };

    create = (entity: T): Promise<T> => {
        return this.http
            .post<T>(this._resourceUrl, entity)
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    updateHttp = (entity: T): Promise<HttpResponse<T>> | Promise<T> => {
        return this.http.put<T>(this._resourceUrl, entity, {observe: 'response'}).toPromise();
    };

    update = (entity: T): Promise<T> => {
        return this.http
            .put<T>(this._resourceUrl, entity)
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    findHttp = (id: number): Promise<HttpResponse<T>> => {
        return this.http.get<T>(`${this._resourceUrl}/${id}`, {observe: 'response'}).toPromise();
    };

    find = (id: number): Promise<T> => {
        return this.http
            .get<T>(`${this._resourceUrl}/${id}`)
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    customerFind = (id: number): Promise<T> => {
        return this.http
            .get<T>(`${this._customerResourceUrl}/${id}`)
            .toPromise()
            .then((responseEntity) => this._entityCreator(responseEntity));
    };

    queryHttp = (req?: any): Promise<HttpResponse<T[]>> => {
        const options = createRequestOption(req);
        return this.http
            .get<T[]>(this._resourceUrl, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    customerQueryHtpp = (req?: any): Promise<HttpResponse<T[]>> => {
        const options = createRequestOption(req);
        return this.http
            .get<T[]>(this._customerResourceUrl, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    searchHttp = (req?: any): Promise<HttpResponse<T[]>> => {
        let options = createRequestOption(req);
        const query = options.get('query');
        // removing page's default sort param to get matching results in order from ES and allow sorting when user tries to sort after results are fetched for a query
        if (query != null && query.length > 0 && query != this.lastSearchedTerm) {
            options = options.delete('sort');
        }
        this.lastSearchedTerm = query;
        return this.http
            .get<T[]>(this._resourceSearchUrl, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    customerSearchHttp = (req?: any): Promise<HttpResponse<T[]>> => {
        const options = createRequestOption(req);
        return this.http
            .get<T[]>(this._customerResourceSearchUrl, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    query = (req?: any): Promise<T[]> => {
        const options = createRequestOption(req);
        return this.http
            .get<T[]>(this._resourceUrl, {params: options})
            .toPromise()
            .then((responseEntities) => responseEntities.map((responseEntity) => this._entityCreator(responseEntity)));
    };

    deleteHttp = (id: number): Promise<HttpResponse<any>> => {
        return this.http.delete<any>(`${this._resourceUrl}/${id}`, {observe: 'response'}).toPromise();
    };

    delete = (id: number): Promise<T> => {
        return this.http.delete<any>(`${this._resourceUrl}/${id}`).toPromise();
    };

    search = (req?: any): Promise<T[]> => {
        const options = createRequestOption(req);
        return this.http
            .get<T[]>(this._resourceSearchUrl, {params: options})
            .toPromise()
            .then((responseEntities) => responseEntities.map((responseEntity) => this._entityCreator(responseEntity)));
    };

    queryPartialHttp = (req?: any): Promise<HttpResponse<T[]>> => {
        const options = createRequestOption(req);
        return this.http
            .get<T[]>(this._resourceUrl, {
                params: options,
                observe: 'response'
            })
            .toPromise();
    };

    queryPartial = (req?: any): Promise<T[]> => {
        const options = createRequestOption(req);
        return this.http.get<T[]>(this._resourceUrl, {params: options}).toPromise();
        // .then(responseEntities => responseEntities.map(responseEntity => this._entityCreator(responseEntity)));
    };

    camelCaseToDashed(str): string {
        return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
    }
}
