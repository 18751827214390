import {FuseNavigation} from '@fuse/types';

export const customerFeature: FuseNavigation[] = [
    {
        id: 'features',
        title: 'Features',
        type: 'group',
        children: [
            {
                id: 'pos-app-access',
                title: 'POS App Access',
                type: 'group',
                children: []
            },
            {
                id: 'client-portal-access',
                title: 'Client Portal Access',
                type: 'group',
                children: []
            }
        ]
    }
];
