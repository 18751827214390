import {faGlasses} from '@fortawesome/free-solid-svg-icons';
import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'orders-management',
        title: 'Order Management',
        translate: 'NAV.ORDER_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'sale-order',
                title: 'Sale Orders',
                translate: 'NAV.SALE_ORDER.TITLE',
                type: 'item',
                icon: 'file_copy',
                url: '/setup/sale-orders'
            },
            {
                id: 'pick-order',
                title: 'Pick Orders',
                translate: 'NAV.PICK_ORDER.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/pick-orders'
            },
            {
                id: 'pack-order',
                title: 'Pack Orders',
                translate: 'NAV.PACK_ORDER.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/pack-orders'
            },
            {
                id: 'load-order',
                title: 'Load Orders',
                translate: 'NAV.LOAD_ORDER.TITLE',
                type: 'item',
                icon: 'airport_shuttle',
                url: '/setup/load-orders'
            },
            {
                id: 'payments',
                title: 'Payments',
                translate: 'NAV.PAYMENTS.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/history/payments'
            },
            {
                id: 'invoices',
                title: 'Invoices',
                translate: 'NAV.INVOICE.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'invoice',
                        title: 'Invoices',
                        translate: 'NAV.INVOICE.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/setup/invoices'
                    },
                    {
                        id: 'credit-note',
                        title: 'Credit Notes',
                        translate: 'NAV.CREDIT_NOTE.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/setup/credit-notes'
                    }
                ]
            },
            {
                id: 'report',
                title: 'Reports',
                translate: 'NAV.REPORTS.TITLE',
                type: 'collapsable',
                icon: 'receipt',
                children: [
                    {
                        id: 'vehicle-inspections',
                        title: 'Vehicle Inspections',
                        translate: 'NAV.VEHICLE_INSPECTIONS.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/history/vehicle-inspections'
                    },
                    {
                        id: 'site-visits',
                        title: 'Site Visits',
                        translate: 'NAV.SITE_VISITS.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/history/site-visits'
                    },
                    {
                        id: 'vehicle-unloads',
                        title: 'Vehicle Unloads',
                        translate: 'NAV.VEHICLE_UNLOADS.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/history/vehicle-unloads'
                    },
                    {
                        id: 'daily-cash-reports',
                        title: 'Cash Ledger',
                        translate: 'NAV.CASH_DEPOSITS.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/history/driver-eod-reports'
                    },
                    // {
                    //     id: 'daily-deposits-reports',
                    //     title: 'Cash Deposits',
                    //     translate: 'NAV.DRIVER_DEPOSITS.TITLE',
                    //     type: 'item',
                    //     icon: 'receipt',
                    //     url: '/history/driver-deposits-reports'
                    // },
                    {
                        id: 'stock-takes',
                        title: 'Stock Takes',
                        translate: 'NAV.STOCK_TAKES.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/history/stock-takes'
                    },
                    {
                        id: 'customer-transactions',
                        title: 'Customer Balance Ledger',
                        translate: 'NAV.CUSTOMER_TRANSACTIONS.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/history/customer-transactions'
                    },
                    {
                        id: 'core-reports',
                        title: 'Core Reports',
                        translate: 'NAV.CORE_REPORTS.TITLE',
                        type: 'item',
                        icon: 'bar_chart',
                        url: '/analytics/reports'
                    },
                    {
                        id: 'eod-survey',
                        title: 'EOD Survey',
                        translate: 'NAV.EOD_SURVEY.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/history/eod-surveys'
                    },
                    {
                        id: 'recipe-execution-report',
                        title: 'Recipe Execution Report',
                        translate: 'NAV.RECIPE_EXECUTION_REPORT.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/history/recipe-execution-report'
                    },
                    {
                        id: 'temperature-reports',
                        title: 'Temperature Report',
                        translate: 'NAV.TEMPERATURE_REPORTS.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/history/temperature-reports'
                    },
                    {
                        id: 'stock-change-condition',
                        title: 'Stock Change Condition',
                        translate: 'NAV.STOCK_CHANGE_CONDITION.TITLE',
                        type: 'item',
                        icon: 'sunny',
                        url: '/history/stock-change-condition'
                    },
                    {
                        id: 'default-product-price-change',
                        title: 'Default Product Price',
                        translate: 'NAV.DEFAULT_PRODUCT_PRICE_CHANGE.TITLE',
                        type: 'item',
                        icon: 'sunny',
                        url: '/history/default-product-price-change'
                    }
                ]
            }
        ]
    },
    {
        id: 'route-management',
        title: 'Route Management',
        translate: 'NAV.ROUTE_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'route-planner',
                title: 'Route Planner',
                translate: 'NAV.ROUTE_PLANNER.TITLE',
                type: 'item',
                icon: 'directions',
                url: '/scheduling/route-planner'
            },
            {
                id: 'route-command',
                title: 'Route Monitor',
                translate: 'NAV.ROUTE_COMMAND.TITLE',
                type: 'item',
                icon: 'directions',
                url: '/scheduling/route-command'
            },
            {
                id: 'route-schedules',
                title: 'Route Schedules',
                translate: 'NAV.ROUTE_SCHEDULES.TITLE',
                type: 'item',
                icon: 'history',
                url: '/scheduling/route-schedules'
            },
            {
                id: 'route-history',
                title: 'Route History',
                translate: 'NAV.ROUTE_HISTORY.TITLE',
                type: 'item',
                icon: 'directions',
                url: '/scheduling/route-history'
            },
            {
                id: 'vehicle',
                title: 'Vehicles',
                translate: 'NAV.VEHICLE.TITLE',
                type: 'collapsable',
                icon: 'local_shipping',
                children: [
                    {
                        id: 'vehicles',
                        title: 'Vehicles',
                        translate: 'NAV.VEHICLE.TITLE',
                        type: 'item',
                        icon: 'local_shipping',
                        url: '/setup/vehicles'
                    },
                    {
                        id: 'vehicle-type',
                        title: 'Vehicle Types',
                        translate: 'NAV.VEHICLE_TYPE.TITLE',
                        type: 'item',
                        icon: 'style',
                        url: '/setup/vehicle-types'
                    },
                    {
                        id: 'safety-profile',
                        title: 'Safety Profiles',
                        translate: 'NAV.VEHICLE_CHECK_LIST.TITLE',
                        type: 'item',
                        icon: 'receipt',
                        url: '/setup/vehicle-check-lists'
                    }
                ]
            },
            {
                id: 'route',
                title: 'Routes',
                translate: 'NAV.ROUTE.TITLE',
                type: 'collapsable',
                icon: 'directions',
                children: [
                    {
                        id: 'routes',
                        title: 'Routes',
                        translate: 'NAV.ROUTE.TITLE',
                        type: 'item',
                        icon: 'directions',
                        url: '/setup/routes'
                    },
                    {
                        id: 'route-type',
                        title: 'Route Types',
                        translate: 'NAV.ROUTE_TYPE.TITLE',
                        type: 'item',
                        icon: 'style',
                        url: '/setup/route-types'
                    },
                    {
                        id: 'stop-type',
                        title: 'Stop Types',
                        translate: 'NAV.STOP_TYPE.TITLE',
                        type: 'item',
                        icon: 'group_work',
                        url: '/setup/stop-types'
                    }
                    // ,
                    // {
                    //     id: 'route-map-symbol',
                    //     title: 'Route Map Symbols',
                    //     translate: 'NAV.ROUTE_MAP_SYMBOL.TITLE',
                    //     type: 'item',
                    //     icon: 'trip_origin',
                    //     url: '/setup/route-map-symbols'
                    // }
                ]
            }
        ]
    },
    {
        id: 'inventory-management',
        title: 'Inventory Management',
        translate: 'NAV.INVENTORY_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'incoming-inventory',
                title: 'Receive/Adjust Inventory',
                translate: 'NAV.INCOMING_INVENTORY.TITLE',
                type: 'item',
                icon: 'store',
                url: '/setup/incoming-inventories'
            },
            {
                id: 'warehouse-transfer',
                title: 'Warehouse Transfer',
                translate: 'NAV.WAREHOUSE_TRANSFER.TITLE',
                type: 'item',
                icon: 'store',
                url: '/setup/warehouse-transfers'
            },
            {
                id: 'inventory-stock',
                title: 'Inventory Stock',
                translate: 'NAV.INVENTORY_STOCK.TITLE',
                type: 'item',
                icon: 'score',
                url: '/setup/inventory-stock'
            },
            {
                id: 'inventory-transaction',
                title: 'Inventory Ledger',
                translate: 'NAV.INVENTORY_TRANSACTIONS.TITLE',
                type: 'item',
                icon: 'file_copy',
                url: '/setup/inventory-transactions'
            },
            {
                id: 'recipes',
                title: 'Recipes',
                translate: 'NAV.RECIPES.TITLE',
                type: 'item',
                icon: 'local_grocery_store',
                url: '/setup/recipes'
            },
            {
                id: 'productionVouchers',
                title: 'Production Vouchers',
                translate: 'NAV.PRODUCTION_VOUCHERS.TITLE',
                type: 'item',
                icon: 'local_grocery_store',
                url: '/setup/production-vouchers'
            },
            {
                id: 'eodInventoryReport',
                title: 'EOD Inventory Report',
                translate: 'NAV.EOD_INVENTORY_REPORT.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/eod-inventory-report'
            },
            {
                id: 'purchase-credit-note',
                title: 'Purchase Debit Note',
                translate: 'NAV.PURCHASE_CREDIT_NOTE.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/purchase-credit-notes'
            }
        ]
    },
    {
        id: 'survey-management',
        title: 'Survey Management',
        translate: 'NAV.SURVEY_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'survey',
                title: 'Surveys',
                translate: 'NAV.SURVEY.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/surveys'
            },
            {
                id: 'surveyReport',
                title: 'Survey Report',
                translate: 'NAV.SURVEY_REPORT.TITLE',
                type: 'item',
                icon: 'receipt',
                url: '/setup/survey-report'
            }
        ]
    },
    {
        id: 'customer-management',
        title: 'Customer Management',
        translate: 'NAV.CUSTOMER_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'customer',
                title: 'Customers',
                translate: 'NAV.CUSTOMER.TITLE',
                type: 'item',
                icon: 'supervisor_account',
                url: '/setup/customers'
            },
            {
                id: 'customer-group',
                title: 'Customer Groups',
                translate: 'NAV.CUSTOMER_GROUP.TITLE',
                type: 'item',
                icon: 'group_work',
                url: '/setup/customer-groups'
            },
            {
                id: 'access-codes',
                title: 'Access Codes',
                translate: 'NAV.DRIVER_ACCESS_CODES.TITLE',
                type: 'item',
                icon: 'library_books',
                url: '/setup/driver-access-codes'
            },
            {
                id: 'service-priority-code',
                title: 'Service Priority Codes',
                translate: 'NAV.SERVICE_PRIORITY_CODE.TITLE',
                type: 'item',
                icon: 'priority_high',
                url: '/setup/service-priority-codes'
            },
            {
                id: 'customer-sms-text',
                title: 'Customer SMS Texts',
                translate: 'NAV.CUSTOMER_SMS_TEXT.TITLE',
                type: 'item',
                icon: 'sms',
                url: '/setup/customer-sms-texts'
            },
            {
                id: 'territories',
                title: 'Territories',
                translate: 'NAV.TERRITORIES.TITLE',
                type: 'item',
                icon: 'south_america',
                url: '/setup/territories'
            }
        ]
    },
    {
        id: 'product-management',
        title: 'Product Management',
        translate: 'NAV.PRODUCT_MANAGEMENT',
        type: 'collapsable',
        children: [
            {
                id: 'product',
                title: 'Products',
                translate: 'NAV.PRODUCT.TITLE',
                type: 'item',
                icon: 'local_grocery_store',
                url: '/setup/products'
            },
            {
                id: 'supplier',
                title: 'Suppliers',
                translate: 'NAV.SUPPLIERS.TITLE',
                type: 'item',
                icon: 'supervisor_account',
                url: '/setup/suppliers'
            },
            {
                id: 'supplier-group',
                title: 'Supplier Groups',
                translate: 'NAV.SUPPLIER_GROUPS.TITLE',
                type: 'item',
                icon: 'group_work',
                url: '/setup/supplier-groups'
            },
            {
                id: 'product-class',
                title: 'Product Class',
                translate: 'NAV.PRODUCT_CLASS.TITLE',
                type: 'item',
                icon: 'group_work',
                url: '/setup/product-class'
            },
            {
                id: 'product-group',
                title: 'Product Group',
                translate: 'NAV.PRODUCT_GROUP.TITLE',
                type: 'item',
                icon: 'category',
                url: '/setup/product-groups'
            },
            {
                id: 'deposit-item',
                title: 'Deposit Items',
                translate: 'NAV.DEPOSIT_ITEM.TITLE',
                type: 'item',
                icon: 'add_shopping_cart',
                url: '/setup/deposit-items'
            },
            // {
            //     id: 'product-list',
            //     title: 'Product Lists',
            //     translate: 'NAV.PRODUCT_LIST.TITLE',
            //     type: 'item',
            //     icon: 'list_alt',
            //     url: '/setup/product-lists'
            // },
            {
                id: 'equipments',
                title: 'Equipments',
                translate: 'NAV.EQUIPMENTS.TITLE',
                type: 'item',
                icon: 'construction',
                url: '/setup/equipments'
            },
            {
                id: 'packaging',
                title: 'Packaging',
                translate: 'NAV.PACKAGING.TITLE',
                type: 'item',
                icon: 'redeem',
                url: '/setup/packagings'
            },
            {
                id: 'delivery-package-type',
                title: 'Delivery Package Types',
                translate: 'NAV.DELIVERY_PACKAGE_TYPE.TITLE',
                type: 'item',
                icon: 'style',
                url: '/setup/delivery-package-types'
            },
            {
                id: 'promotions',
                title: 'Promotions',
                translate: 'NAV.PROMOTIONS.TITLE',
                type: 'item',
                icon: 'money',
                url: '/setup/promotions'
            },
            {
                id: 'price-list',
                title: 'Price Lists',
                translate: 'NAV.PRICE_LIST.TITLE',
                type: 'item',
                icon: 'money',
                url: '/setup/price-lists'
            },
            // {
            //     id: 'order-rejection-code',
            //     title: 'Order Rejection Codes',
            //     translate: 'NAV.ORDER_REJECTION_CODE.TITLE',
            //     type: 'item',
            //     icon: 'not_interested',
            //     url: '/setup/order-rejection-codes'
            // },
            // {
            //     id: 'product-tax-category',
            //     title: 'Product Tax Categories',
            //     translate: 'NAV.PRODUCT_TAX_CATEGORY.TITLE',
            //     type: 'item',
            //     icon: 'attach_money',
            //     url: '/setup/product-tax-categories'
            // },
            {
                id: 'unit-of-measure',
                title: 'Unit of Measure',
                translate: 'NAV.UNIT_OF_MEASURE.TITLE',
                type: 'item',
                icon: 'looks_one',
                url: '/setup/unit-of-measures'
            },
            {
                id: 'product-imei-track',
                title: 'IMEI Tracking',
                translate: 'NAV.PRODUCT_IMEI_TRACK.TITLE',
                type: 'item',
                icon: 'library_books',
                url: '/setup/product-imei-tracks'
            }
        ]
    },
    // {
    //     id: 'task-management',
    //     title: 'Task Management',
    //     translate: 'NAV.TASK_MANAGEMENT',
    //     type: 'item',
    //     icon: 'mobile_friendly',
    //     url: '/setup/task-management'
    // },
    // {
    //     id: 'file-management',
    //     title: 'File Management',
    //     translate: 'NAV.FILE_MANAGEMENT',
    //     type: 'item',
    //     icon: 'file_copy',
    //     url: '/setup/file-management'
    // },
    {
        id: 'service-orders-management',
        title: 'Service Orders',
        translate: 'NAV.SERVICE_ORDERS',
        type: 'collapsable',
        children: [
            {
                id: 'serviceItems',
                title: 'Service Items',
                translate: 'NAV.SERVICE_ITEMS.TITLE',
                type: 'item',
                icon: 'support_agent',
                url: '/service-orders/service-items'
            },
            {
                id: 'serviceOrders',
                title: 'Service Orders',
                translate: 'NAV.SERVICE_ORDERS',
                type: 'item',
                icon: 'room_service',
                url: '/service-orders/service-orders'
            }
        ]
    },
    {
        id: 'data-analytics',
        title: 'Analytics',
        translate: 'NAV.DATA_ANALYTICS',
        type: 'item',
        icon: 'file_copy',
        url: '/setup/data-analytics'
    }
];
