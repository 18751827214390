import moment from 'moment';

export class MposDate {
    constructor(dateObj?: {year: number; month: number; date: number}) {
        this.year = dateObj.year;
        this.month = dateObj.month;
        this.date = dateObj.date;
    }
    year: number;
    month: number;
    date: number;

    static newInstance(obj: any): MposDate {
        let instance = null;
        if (obj) {
            if (moment.isMoment(obj)) {
                instance = new MposDate({
                    year: obj.year(),
                    month: obj.month() + 1,
                    date: obj.date()
                });
            } else if (typeof obj === 'string') {
                const dateString = obj.split('-');
                instance = new MposDate({
                    year: +dateString[0],
                    month: +dateString[1],
                    date: +dateString[2]
                });
            } else if (obj instanceof Date) {
                instance = new MposDate({
                    year: obj.getFullYear(),
                    month: obj.getMonth() + 1,
                    date: obj.getDate()
                });
            } else if (typeof obj === 'number') {
                const date = new Date(obj);
                instance = new MposDate({
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    date: date.getDate()
                });
            } else if (obj instanceof MposDate) {
                instance = obj;
            }
        }
        return instance;
    }

    static currentDate(): MposDate {
        return MposDate.newInstance(moment());
    }

    static currentTime(): string {
        return moment().format('HH:mm');
    }

    static inUKDateFormat(date: string): string {
        if (date) {
            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`;
        }
        return null;
    }

    static inUKDateFormatWithTime(date: string): string {
        if (date) {
            const dateParts = date.split('T');
            if (!dateParts || dateParts.length <= 1) {
                return null;
            }

            const [year, month, day] = dateParts[0].split('-');
            const [hh, mm, ss] = dateParts[1].split(':');
            return `${day}/${month}/${year} ${hh}:${mm}`;
        }
        return null;
    }

    static fromUTCDateAndTimeStringToLocalDateString(date: string, time: string): string {
        const utcDateTimeString = date + ' ' + time;
        const utcDateTime = moment.utc(utcDateTimeString, 'YYYY-MM-DD HH:mm');
        const localDateString = utcDateTime.local().format('YYYY-MM-DD');
        return localDateString;
    }

    static fromUTCDateAndTimeStringToLocalTimeString(date: string, time: string): string {
        const utcDateTimeString = date + ' ' + time;
        const utcDateTime = moment.utc(utcDateTimeString, 'YYYY-MM-DD HH:mm');
        const localTimeString = utcDateTime.local().format('HH:mm');
        return localTimeString;
    }

    static formatDateToDDMMYYYY(date: string): string {
        if (!date) {
            return 'Not yet completed';
        }
        const dd = date.substring(8);
        const mm = date.substring(5, 7);
        const yyyy = date.substring(0, 4);
        return `${dd}-${mm}-${yyyy}`;
    }

    static getDateDifferenceInDays(date1Str: any, date2Str: any): number {
        if (date1Str == null || date2Str == null) {
            return 0;
        }
        const date1 = new Date(date1Str);
        const date2 = new Date(date2Str);
        const diff = Math.abs(date1.getTime() - date2.getTime());
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    equals(date: any): boolean {
        if (date) {
            const mposDate = MposDate.newInstance(date);
            if (mposDate) {
                return this.year === mposDate.year && this.month === mposDate.month && this.date === mposDate.date;
            }
        }
        return false;
    }

    toString(): string {
        return `${this.year}-${this.padLeftZero(this.month, 2)}-${this.padLeftZero(this.date, 2)}`;
    }

    toJSON(): string {
        return this.toString();
    }

    asMoment(): moment.Moment {
        return moment({
            year: this.year,
            month: this.month - 1,
            date: this.date
        });
    }

    asDate(): Date {
        return new Date(this.year, this.month - 1, this.date);
    }

    addDays(numberOfDays: number): MposDate {
        return MposDate.newInstance(this.asMoment().add(numberOfDays, 'day'));
    }

    private padLeftZero(numberToPad: number, noOfDigits: number): string {
        const str = '' + numberToPad;
        return ('0'.repeat(noOfDigits) + str).substring(str.length);
    }

    addMonths(numberOfMonths: number): MposDate {
        return MposDate.newInstance(this.asMoment().add(numberOfMonths, 'month'));
    }
}
