import {HttpParams} from '@angular/common/http';
import {FilterParams} from 'app/blocks/util/filter-params';

export const createRequestOption = (req?: any): HttpParams => {
    let options: HttpParams = new HttpParams();
    if (req) {
        Object.keys(req).forEach((key) => {
            // if (key !== 'sort') {
            options = options.set(key, req[key]);
            // }
        });

        // if (filters) {
        //     for (const filter of filters) {
        //         if (filter.currentValue) {
        //             options = options.set(filter.columnName, filter.currentValue.toString());
        //         }
        //     }
        // }
        // if (req.sort) {
        //     req.sort.forEach(val => {
        //         options = options.append('sort', val);
        //     });
        // }
    }
    return options;
};
