export enum Resource {
    BRANCHES = 'branches',
    EXECUTIONS = 'executions',
    BACKUPS = 'backups',
    RESTORES = 'restores',
    BANKS = 'banks',
    COMPANIES = 'companies',
    COUNTRIES = 'countries',
    CURRENCIES = 'currencies',
    TENANT_MENU_ITEM = 'tenantMenuItems',
    CREDIT_NOTES = 'creditNotes',
    ENTITY_AUDIT = 'entityAudit',
    DELIVERY_PACKAGE_TYPES = 'deliveryPackageTypes',
    WAREHOUSES = 'warehouses',
    WAREHOUSE_TYPES = 'warehouseTypes',
    MAJOR_ACCOUNT_CODES = 'majorAccountCodes',
    HANDHELD_DOWNLOADS = 'handheldDownloads',
    EMPLOYEES = 'employees',
    DB_USER = 'dbuser',
    EMPLOYEE_GROUPS = 'employeeGroups',
    SERVICE_PRIORITY_CODES = 'servicePriorityCodes',
    CUSTOM_FIELD_GROUPS = 'customFieldGroups',
    CUSTOM_FIELDS = 'customFields',
    PRODUCT_ATTRIBUTE_TYPES = 'productAttributeTypes',
    DEPOSIT_ITEMS = 'depositItems',
    STATEMENT_CODES = 'statementCodes',
    STATEMENT_TYPES = 'statementTypes',
    BILLING_FREQUENCY_TYPES = 'billingFrequencyTypes',
    IMAGES = 'images',
    PROFILES = 'profiles',
    REPORTS = 'reports',
    VEHICLE_UNLOADS = 'vehicleUnloads',
    INVOICES = 'invoices',
    SUPPLIERS = 'suppliers',
    TENANTS = 'tenants',
    CUSTOM_STATEMENT_ADDRESSES = 'customStatementAddresses',
    DUNNING_MESSAGE_CODES = 'dunningMessageCodes',
    CREDIT_CLASSES = 'creditClasses',
    CREDIT_TERMS = 'creditTerms',
    CUSTOMER_SMS_TEXTS = 'customerSmsTexts',
    DELIVERY_METHODS = 'deliveryMethods',
    DELIVERY_ORDERS = 'deliveryOrders',
    DELIVERY_POINTS = 'deliveryPoints',
    DURATION_PRICE_LISTS = 'durationPriceLists',
    DYNAMIC_HEADER_CODES = 'dynamicHeaderCodes',
    HANDHELD_PROFILES = 'handheldProfiles',
    INCOMING_INVENTORIES = 'incomingInventories',
    INVENTORY_PRODUCT_ENTRIES = 'inventoryProductEntries',
    INVENTORY_REASONS = 'inventoryReasons',
    INVENTORY_REASON_GROUPS = 'inventoryReasonGroups',
    LOAD_ORDERS = 'loadOrders',
    LIST_PRODUCT_PRICES = 'listProductPrices',
    PACK_ORDERS = 'packOrders',
    PICK_ORDERS = 'pickOrders',
    PRICE_LIST_TYPES = 'priceListTypes',
    DELIVERY_ITEMS = 'deliveryItems',
    DELIVERY_PACKAGES = 'deliveryPackages',
    ORDER_REJECTION_CODES = 'orderRejectionCodes',
    STOP_TYPES = 'stopTypes',
    DELIVERY_POINT_MESSAGES = 'deliveryPointMessages',
    DELIVERY_POINT_MESSAGE_TYPES = 'deliveryPointMessageTypes',
    TIME_WINDOWS = 'timeWindows',
    NOTES = 'notes',
    CONSIGNMENT_ITEMS = 'consignmentItems',
    DEPOSIT_ITEM_INFOS = 'depositItemInfos',
    CUSTOMERS = 'customers',
    VEHICLE_INSPECTIONS = 'vehicleInspectionReports',
    SITE_VISITS = 'siteVisits',
    DRIVER_EOD_REPORTS = 'driverEodReports',
    DRIVER_DEPOSIT_REPORTS = 'driverDepositsReports',
    TEMPERATURE_REPORTS = 'temperatureReports',
    EQUIPMENTS = 'equipments',
    SERVICE_ITEMS = 'serviceItems',
    SERVICE_ORDERS = 'serviceOrders',
    PAYMENTS = 'payments',
    PAYMENT_TYPES = 'paymentTypes',
    CUSTOMER_STATUSES = 'customerStatuses',
    CUSTOMER_STATUS_REASONS = 'customerStatusReasons',
    CUSTOMER_STATUS_REASON_GROUPS = 'customerStatusReasonGroups',
    ORDER_ITEMS = 'orderItems',
    ORDER_ITEM_REASONS = 'orderItemReasons',
    ORDER_PRIORITIES = 'orderPriorities',
    ORDER_STATUS_REASONS = 'orderStatusReasons',
    PRODUCT_CLASSES = 'productClasses',
    PRODUCT_GROUPS = 'productGroups',
    UNIT_OF_MEASURES = 'unitOfMeasures',
    PACKAGINGS = 'packagings',
    PROMOTIONS = 'promotions',
    PRICE_LISTS = 'priceLists',
    PRODUCT_PACKAGINGS = 'productPackagings',
    PRODUCTS = 'products',
    UK_VAT_RATES = 'ukVatRates',
    PRODUCT_LISTS = 'productLists',
    SALE_ORDERS = 'saleOrders',
    CLIENT_SALE_ORDERS = 'clientSaleOrders',
    STANDING_ORDERS = 'standingOrders',
    STANDING_LOAD_ORDERS = 'standingLoadOrders',
    SALE_ORDER_TYPES = 'saleOrderTypes',
    SALE_ORDER_STATUSES = 'saleOrderStatuses',
    SITE_VISIT_SKIP_REASONS = 'siteVisitSkipReasons',
    VEHICLES = 'vehicles',
    VEHICLE_TYPES = 'vehicleTypes',
    ROUTE_TYPES = 'routeTypes',
    ROUTE_MAP_SYMBOLS = 'routeMapSymbols',
    ROUTES = 'routes',
    ALERTS = 'alerts',
    CUSTOMER_GROUPS = 'customerGroups',
    DURATION_PRODUCT_PRICE_INFOS = 'durationProductPriceInfos',
    SETTING_META_DATA = 'settingMetaData',
    ROUTE_DISPATCH_OPTIONS = 'routeDispatchOptions',
    SURVEYS = 'surveys',
    VEHICLE_CHECKLISTS = 'vehicleCheckLists',
    DAILY_ACTIVITY_SUMMARIES = 'dailyActivitySummaries',
    DISPLAY_SETTINGS = 'displaySettings',
    EMPLOYEE_TARGETS = 'employeeTargets',
    REASON_CODES = 'reasonCodes',
    FRANCHISES = 'franchises',
    DEMAND_REPORTS = 'demandReports',
    EOD_SURVEYS = 'eodSurveys',
    RECIPE_EXECUTION_REPORT = 'recipeExecutionReport',
    MAIL_AUDIT = 'mailAudits',
    RECIPES = 'recipes',
    REBATE_TIERS = 'rebateTiers',
    BLOCKING_MESSAGES = 'blockingMessages',
    STOCK_CHANGE_CONDITION = 'stockChangeCondition',
    PURCHASE_INVOICE = 'purchaseInvoice',
    ROUTE_SCHEDULE = 'routeSchedules',
    ROUTE_SCHEDULE_CRUD = 'rdm/routeSchedules',
    PRODUCTION_VOUCHERS = 'productionVouchers',
    EOD_INVENTORY_REPORT = 'eodInventoryReport',
    SURVEY_REPORT = 'surveyReport',
    ACCOUNTS_PACKAGES = 'accountsPackages',
    BRANCH_PAYMENTS = 'branchPayments',
    BRANCH_TRANSACTIONS = 'branchTransactions',
    PURCHASE_PAYMENTS = 'purchasePayments',
    PURCHASE_CREDIT_NOTES = 'purchaseCreditNotes',
    INTEGRATION_MAPPINGS = 'integrationMappings',
    SUPPLIER_TRANSACTIONS = 'supplierTransactions',
    BIN_TYPES = 'binTypes',
    BINS = 'bins',
    CONSIGNMENT_ORDERS = 'consignmentOrders',
    BUDGETS = 'budgets',

    // FIXME: Fix the following. Some are not needed
    IMPORT_DATA = 'importData',
    HAULIER_ORDERS = 'haulierOrders',
    HAULIER_CUSTOMERS = 'haulierCustomers',
    LOCATION_POINTS = 'locationPoints',
    TRAILERS = 'trailers',
    TRAILER_TYPES = 'trailerTypes',
    ORGANISATIONS = 'organisations',
    CONTACT_INFOS = 'contactInfos',
    FUEL_SURCHARGE_GROUPS = 'fuelSurchargeGroups',
    PEOPLE = 'people',

    // END of FIXME
    STOCK_TAKES = 'stockTakes',
    PURCHASE_ORDERS = 'purchaseOrders',

    // FIXME: Remove the following as they are not "resources" on the backend
    IN_CGST_RATES = 'inCgstRates',
    IN_SGST_RATES = 'inSgstRates',
    IN_IGST_RATES = 'inIgstRates',
    MPOS_TEMPLATE = 'mposTemplate',
    WAREHOUSE_TRANSFERS = 'warehouseTransfers',
    DRIVER_ACCESS_CODES = 'driverAccessCodes',
    INVENTORY_TRANSACTIONS = 'inventoryTransactions',
    CUSTOMER_TRANSACTIONS = 'customerTransactions',
    CUSTOMER_AGING_REPORTS = 'CustomerAgingReports',
    CASH_JOURNAL_REPORTS = 'CashJournalReports',
    SHORTFALL_REPORT = 'shortfallReports',
    APP_REQUESTS = 'appRequests',
    PRODUCT_COST_CHANGES = 'productCostChanges',
    RE_INDEX = 'reIndexs',
    USERS = 'users',
    PRODUCT_IMEI_TRACKS = 'productImeiTracks',
    WORKSHOP = 'workshops',
    ROI_VAT_RATES = 'roiVatRates',
    ROLES = 'roles',
    TERRITORIES = 'territories',
    APP_VERSION = 'appVersion',
    GCT_RATES = 'gctRates',
    DEFAULT_PRODUCT_PRICE_CHANGES = 'defaultProductPriceChanges',
    ASYNC_REQUESTS = 'asyncRequests',
    EMAIL_WORKFLOW = 'emailWorkflows',
    WORKFLOWS = 'workflows',
    ROUTE_TARGETS = 'routeTargets',
    CATALOGUES = 'catalogues',
    PRESENTATIONS = 'presentations',
    RESOURCE_ITEMS = 'resourceItems',
    RESOURCE_GROUPS = 'resourceGroups',
    SUPPLIER_PAYMENT_TERMS = 'supplierPaymentTerms',
    EMPLOYEE_EXPENSES = 'employeeExpenses',
    EXPENSE_TYPES = 'expenseTypes',
    EXPENSE_PAYMENT_METHOD = 'expensePaymentMethod',
    BRANDS = 'brands',
    VEHICLE_SKILL = 'vehicleSkills',
    CUSTOM_PDF_TEMPLATES = 'customPdfTemplates',
    SUPPLIER_GROUPS = 'supplierGroups',
    VIDEOS = 'videos',
    PRODUCT_RE_SEQUENCE = 'productReSequence'
}
